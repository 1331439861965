@-webkit-keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@-webkit-keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  80% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  80% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  background-color: #eee;
  color: #333;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

@media (min-width: 0) {
  .heading-primary {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1200px) {
  .heading-primary {
    margin-bottom: 5rem;
  }
}

.heading-primary--main {
  display: block;
  font-size: 5rem;
  font-weight: 400;
  letter-spacing: 2rem;
  -webkit-animation-name: moveInLeft;
          animation-name: moveInLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  /* animation-delay: 3s; 
      animation-iteration-count: 3;*/
}

@media (min-width: 0) {
  .heading-primary--main {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
  }
}

@media (min-width: 600px) {
  .heading-primary--main {
    font-size: 3rem;
    letter-spacing: 1rem;
  }
}

@media (min-width: 900px) {
  .heading-primary--main {
    font-size: 4rem;
    letter-spacing: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .heading-primary--main {
    font-size: 5rem;
    letter-spacing: 2rem;
  }
}

.heading-secondary {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.4rem;
  display: inline-block;
  width: 100%;
  background-color: #3577b1;
  color: #fff;
  -webkit-box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.25);
          box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.25);
}

@media (min-width: 0) {
  .heading-secondary {
    font-size: 2rem;
    -webkit-clip-path: none;
            clip-path: none;
  }
}

@media (min-width: 900px) {
  .heading-secondary {
    font-size: 2.8rem;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 50%, 50% 100%, 25% 50%, 0 50%);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 75% 50%, 50% 100%, 25% 50%, 0 50%);
  }
}

.heading-tertiary {
  font-size: 2rem;
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
}

.description {
  color: #333;
}

@media (min-width: 0) {
  .description {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  .description {
    font-size: 1.8rem;
  }
}

@media (min-width: 1200px) {
  .description {
    font-size: 2rem;
  }
}

.description:not(:last-child) {
  margin-bottom: 3rem;
}

.paragraph {
  font-size: 1.8rem;
  color: #333;
}

.paragraph:not(:last-child) {
  margin-bottom: 3rem;
}

.heading-experience {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
  text-transform: uppercase;
}

.heading-experience__date {
  margin-top: 1rem;
}

.heading-experience a:link, .heading-experience a:visited {
  color: #1c4c77;
  text-decoration: none;
}

.heading-experience a:hover {
  background-color: #3577b1;
  color: #fff;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.heading-experience a:active {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.heading-techstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 16rem;
  font-size: 2.5rem;
  color: #333;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.u-center-text {
  text-align: center;
}

.u-margin-bottom-big {
  margin-bottom: 6rem;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem;
}

.u-margin-bottom-small {
  margin-bottom: 2rem;
}

.u-margin-top-big {
  margin-top: 8rem;
}

.u-margin-top-huge {
  margin-top: 10rem;
}

.u-anchor {
  margin-bottom: 6rem;
}

@media (min-width: 0) {
  .u-anchor {
    margin-bottom: 2rem;
  }
}

@media (min-width: 600px) {
  .u-anchor {
    margin-bottom: 6rem;
  }
}

.cloudtag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

@-webkit-keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 10rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
}

.btn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn:hover::after {
  -webkit-transform: scaleX(1.4) scaleY(1.6);
          transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active {
  outline: none;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.btn--white {
  background-color: #fff;
  color: #777;
}

.btn--white::after {
  background-color: #fff;
}

.btn--green {
  background-color: #498350;
  color: #fff;
}

.btn--green::after {
  background-color: #38704b;
}

.btn--blue {
  background-color: #40607d;
  color: #fff;
}

.btn--blue::after {
  background-color: #40607d;
}

.btn--purple {
  background-color: #78668f;
  color: #fff;
}

.btn--purple::after {
  background-color: #684e88;
}

.btn--animated {
  -webkit-animation: moveInBottom 0.5s ease-out 0.75s;
          animation: moveInBottom 0.5s ease-out 0.75s;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #3577b1;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #3577b1;
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.btn-text:hover {
  background-color: #3577b1;
  color: #fff;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.btn-text:active {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.card {
  -webkit-perspective: 150rem;
          perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 60rem;
}

.card__side {
  color: #fff;
  font-size: 2rem;
  height: 60rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side--front {
  background-color: #fff;
}

.card__side--back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card__side--back-1 {
  background: -webkit-gradient(linear, left top, right bottom, from(#498350), to(#38704b));
  background: linear-gradient(to right bottom, #498350, #38704b);
}

.card__side--back-2 {
  background: -webkit-gradient(linear, left top, right bottom, from(#5a7996), to(#40607d));
  background: linear-gradient(to right bottom, #5a7996, #40607d);
}

.card__side--back-3 {
  background: -webkit-gradient(linear, left top, right bottom, from(#78668f), to(#684e88));
  background: linear-gradient(to right bottom, #78668f, #684e88);
}

.card:hover .card__side--front {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
}

.card__picture {
  background-size: cover;
  height: 23rem;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card__picture--1 {
  background: -webkit-gradient(linear, left top, right bottom, from(#498350), to(#498350));
  background: linear-gradient(to right bottom, #498350, #498350);
  /* background-image: linear-gradient(
					to right bottom,
					$color-green-light,
					$color-green-dark
				),
				url(../img/photo-dev-code.jpg);*/
}

.card__picture--2 {
  background: -webkit-gradient(linear, left top, right bottom, from(#5a7996), to(#5a7996));
  background: linear-gradient(to right bottom, #5a7996, #5a7996);
  /* background-image: linear-gradient(
					to right bottom,
					$color-blue-light,
					$color-blue-dark
				),
				url(../img/photo-scrum-board.pg.jpg); */
}

.card__picture--3 {
  background: -webkit-gradient(linear, left top, right bottom, from(#78668f), to(#78668f));
  background: linear-gradient(to right bottom, #78668f, #78668f);
  /* background-image: linear-gradient(
					to right bottom,
					$color-purple-light,
					$color-purple-dark
				),
				url(../img/photo-user-story.jpg); */
}

.card__heading {
  position: absolute;
  top: 2.5rem;
  left: 25%;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.card__heading-span--1 {
  background: -webkit-gradient(linear, left top, right bottom, from(rgba(73, 131, 80, 0.85)), to(rgba(56, 112, 75, 0.85)));
  background: linear-gradient(to right bottom, rgba(73, 131, 80, 0.85), rgba(56, 112, 75, 0.85));
}

.card__heading-span--2 {
  background: -webkit-gradient(linear, left top, right bottom, from(rgba(90, 121, 150, 0.85)), to(rgba(64, 96, 125, 0.85)));
  background: linear-gradient(to right bottom, rgba(90, 121, 150, 0.85), rgba(64, 96, 125, 0.85));
}

.card__heading-span--3 {
  background: -webkit-gradient(linear, left top, right bottom, from(rgba(120, 102, 143, 0.85)), to(rgba(104, 78, 136, 0.85)));
  background: linear-gradient(to right bottom, rgba(120, 102, 143, 0.85), rgba(104, 78, 136, 0.85));
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: center;
}

.card__details {
  padding: 3rem;
  margin-top: 14rem;
}

.card__skills {
  padding: 3rem;
}

.card__skills ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__skills ul li {
  text-align: center;
  font-size: 1.8rem;
  padding: 0.5rem;
}

@media (min-width: 0) {
  .card__skills ul li {
    font-size: 1.8rem;
  }
}

@media (min-width: 900px) {
  .card__skills ul li {
    font-size: 1.6rem;
  }
}

@media (min-width: 1200px) {
  .card__skills ul li {
    font-size: 1.8rem;
  }
}

.composition {
  position: relative;
  margin-top: 3rem;
}

.composition__photo {
  width: 55%;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 1px;
  position: absolute;
  z-index: 10;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline-offset: 0.75rem;
  /* &--p3 {
			left: 20%;
			top: 10rem;
		} */
}

.composition__photo--p1 {
  left: 0;
  top: -2rem;
  z-index: 15;
}

@media (min-width: 0) {
  .composition__photo--p1 {
    top: -2rem;
  }
}

@media (min-width: 2020px) {
  .composition__photo--p1 {
    top: -8rem;
  }
}

.composition__photo--p2 {
  right: 0;
  top: 2rem;
}

@media (min-width: 0) {
  .composition__photo--p2 {
    top: 2rem;
  }
}

@media (min-width: 2020px) {
  .composition__photo--p2 {
    top: -7rem;
  }
}

.composition__photo:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
          box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}

.composition:hover .composition__photo:not(:hover) {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.composition__cert-images {
  margin-right: 3rem;
}

.experience-section {
  background-size: cover;
  color: #333;
}

@media (min-width: 0) {
  .experience-section {
    padding: 2rem 5rem;
    -webkit-clip-path: none;
            clip-path: none;
  }
}

@media (min-width: 900px) {
  .experience-section {
    padding: 2rem 15rem;
    -webkit-clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0% 100%);
  }
}

@media (min-width: 2020px) {
  .experience-section {
    padding: 2rem 25rem;
  }
}

.experience-section__video {
  position: relative;
  min-width: 100%;
  height: 12rem;
  padding: 0;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.8;
}

@media (min-width: 0) {
  .experience-section__video {
    background-size: 100%;
  }
}

@media (min-width: 1536px) {
  .experience-section__video {
    background-size: cover;
    background-position: rem;
  }
}

.experience-section__photo {
  height: 15rem;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25);
  background-blend-mode: hue;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#a8cef0), to(#3577b1)), url(../img/experience-background.jpg);
  background-image: linear-gradient(to right bottom, #a8cef0, #3577b1), url(../img/experience-background.jpg);
}

@media (min-width: 0) {
  .experience-section__photo {
    background-size: 100%;
  }
}

@media (min-width: 1536px) {
  .experience-section__photo {
    background-size: cover;
    background-position: 1rem;
  }
}

.experience-section__box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(168, 206, 240, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* &--1 {
			background-color: rgba($color-blue-light, 0.8);
		}

		&--2 {
			background-color: rgba($color-green-light, 0.8);
		}

		&--3 {
			background-color: rgba($color-purple-light, 0.8);
		} */
}

.experience-section__roles {
  margin-top: 0.4rem;
  padding: 0;
}

.experience-section__roles ul {
  list-style: none;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.experience-section__roles ul li {
  padding: 0;
}

.experience-section__roles ul li:not(:last-child) {
  margin-right: 2rem;
}

.row {
  max-width: 80%;
  margin: 0 auto;
}

@media (min-width: 0) {
  .row {
    max-width: 90%;
  }
}

@media (min-width: 600px) {
  .row {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .row {
    max-width: 80%;
  }
}

.row:not(:last-child) {
  margin-bottom: 4rem;
}

.row::after {
  content: '';
  display: table;
  clear: both;
}

.row [class^='col-'] {
  float: left;
}

.row [class^='col-']:not(:last-child) {
  margin-right: 6rem;
}

.container-contact {
  margin: 0 15rem;
}

.container-contact__item {
  text-align: center;
}

.container-contact__item--1 {
  font-size: 6rem;
  color: #333;
}

.container-contact__item--2 {
  font-size: 6rem;
  color: #0a66c2;
}

.container-contact__item--3 {
  font-size: 6rem;
  color: #000;
}

.container-contact__item a:link, .container-contact__item a:visited {
  color: #333;
  text-decoration: none;
}

.container-contact__item a:hover, .container-contact__item a:active {
  color: #3577b1;
  font-weight: 700;
}

.container-footer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  width: 100%;
  background-color: #3577b1;
}

.container-footer__box {
  text-align: center;
}

.container-footer__box--1 {
  padding: 0.5rem;
  background-color: #333;
}

.container-footer__box--2 {
  padding: 2rem 4rem;
  color: #fff;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background-color: #3577b1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 10%;
  text-transform: uppercase;
  z-index: 30;
}

@media (min-width: 0) {
  .header {
    height: 9rem;
  }
}

@media (min-width: 900px) {
  .header {
    height: 10rem;
  }
}

.header__image-box {
  width: 100%;
  height: 100%;
}

@media (min-width: 0) {
  .header__image-box {
    position: absolute;
  }
}

@media (min-width: 1200px) {
  .header__image-box {
    position: relative;
  }
}

.header__image {
  -webkit-animation-name: moveInLeft;
          animation-name: moveInLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

@media (min-width: 0) {
  .header__image {
    height: 90%;
    margin-top: 1.25rem;
  }
}

@media (min-width: 600px) {
  .header__image {
    height: 100%;
    margin-top: 3px;
  }
}

@media (min-width: 900px) {
  .header__image {
    height: 100%;
    margin-top: 0;
    margin-top: 0;
  }
}

.header__title-box {
  position: absolute;
  top: 75%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.nav {
  position: fixed;
  top: 10rem;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
  background-color: #333;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.45);
          box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.45);
  padding: 0 7.7%;
  z-index: 30;
}

@media (min-width: 0) {
  .nav {
    height: 1rem;
    top: 9rem;
  }
}

@media (min-width: 900px) {
  .nav {
    height: 2.8rem;
    top: 9.6rem;
  }
}

.nav label {
  position: absolute;
  top: -6.5rem;
  right: 2.4rem;
  color: #fff;
  margin: 0;
}

@media (min-width: 0) {
  .nav label {
    display: block;
  }
}

@media (min-width: 900px) {
  .nav label {
    display: none;
  }
}

.nav #toggle {
  display: none;
}

#toggle:checked + .nav__menu {
  display: list-item;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
          transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

.nav__background {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
  background-image: radial-gradient(#a8cef0, #1c4c77);
  z-index: 1000;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}

@media (min-width: 0) {
  .nav__background {
    top: 4.5rem;
    right: 4.5rem;
  }
}

@media (min-width: 900px) {
  .nav__background {
    display: none;
  }
}

.nav__menu {
  list-style: none;
  margin-right: 4rem;
}

@media (min-width: 0) {
  .nav__menu {
    position: fixed;
    right: -4rem;
    text-align: center;
    display: none;
    font-size: 2.5rem;
    width: 100vw;
    height: 100vh;
    background-color: #333;
  }
}

@media (min-width: 900px) {
  .nav__menu {
    display: block;
    font-size: 1.6rem;
    height: 2.8rem;
    text-align: right;
    margin-right: 12%;
  }
}

.nav__item {
  display: inline-block;
}

@media (min-width: 0) {
  .nav__item {
    display: block;
    margin: 3rem;
  }
}

@media (min-width: 900px) {
  .nav__item {
    display: inline-block;
    margin: 0;
  }
}

.nav__item:not(:last-child) {
  margin-right: 3rem;
}

.nav__link:link, .nav__link:visited {
  color: #f7f7f7;
  background-color: #333;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nav__link:hover, .nav__link:active {
  color: #a8cef0;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
          box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.4);
  -webkit-transform: rotate(5deg) scale(1.3);
          transform: rotate(5deg) scale(1.3);
}

.section-home {
  padding: 18rem 1rem 5rem;
}

@media (min-width: 0) {
  .section-home {
    display: block;
    padding: 12rem 0 0;
    overflow-x: hidden;
  }
}

@media (min-width: 900px) {
  .section-home {
    padding: 15rem 1rem 0;
  }
}

@media (min-width: 1200px) {
  .section-home {
    padding: 19rem 1rem 5rem;
  }
}

.section-about {
  padding: 0 0 5rem;
  margin: 5rem 0 5rem;
}

.section-experience {
  padding: 0 0 5rem;
  margin: 0 0 5rem;
}

.section-contact {
  padding: 0 0 5rem;
  margin: 0 0 5rem;
}

.section-footer {
  padding: 0;
  margin: 0;
}

/* .section-feature {
	padding: 20rem 0;
	background-image: linear-gradient(
			to right bottom,
			rgba($color-blue-light, 0.8),
			rgba($color-blue-dark, 0.8)
		),
		url(../img/nat-4.jpg);
	background-size: cover;
	transform: skewY(-7deg);
	margin-top: -10rem;

	& > * {
		transform: skewY(7deg);
	}
} */
